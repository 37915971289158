import { createSlice } from '@reduxjs/toolkit'

const initialState = false

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    toggleLoading: (state) => {
      state = !state;
      return state;
    },
    setLoading : (state, action) => {
      state = action.payload
      return state;
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggleLoading, setLoading } = loadingSlice.actions

export default loadingSlice.reducer