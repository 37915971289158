import "./Body.css";
import { Box, Divider, Typography } from "@mui/material";

import React from "react";
import { useSelector } from "react-redux";

function ShowContent() {
  const totalPages = useSelector((state) => state.totalPages);

  if (!totalPages.url) {
    return;
  }

  return (
    <div style={{ marginTop: "1em" }}>
      <Typography
        style={{
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "bold",
          margin: "auto",
        }}
      >
        {totalPages.url}
      </Typography>
      <Divider></Divider>
      <Box style={{ marginTop: "0.25em" }}>
        <Typography
          style={{
            textAlign: "center",
            fontSize: "14px",
            margin: "auto",
            marginBottom: "0.25em",
          }}
        >
          Total pages detected: {totalPages.pages.length}
        </Typography>

        <ul className="list-group mt-5">
          {totalPages.pages.map((page) => (
            <li key={page} className="list-group-item fs-6">{page}</li>
          ))}
        </ul>
      </Box>
    </div>
  );
}

export default ShowContent;
