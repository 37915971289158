import "./Body.css";
import { useState } from "react";
import {
  Card,
  Typography,
  Fade,
  CardContent,
  Container,
  Box,
  TextField,
  Button,
} from "@mui/material";

import React from "react";
import Form from "./Form";
import ShowContent from "./Show";

function Body() {

  return (
    <Container className="body">
      <Fade in={true} appear={true} unmountOnExit>
        <Card className="card col-12 col-md-8">
          <CardContent style={{
            width:"100%"
          }}>
            <Form></Form>
            <ShowContent></ShowContent>
          </CardContent>
        </Card>
      </Fade>
    </Container>
  );
}

export default Body;
