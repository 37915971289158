import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pages: [],
  url: null
}

export const totalPagesSlice = createSlice({
  name: 'totalPages',
  initialState,
  reducers: {
    addPage: (state, action) => {
      state.pages.push(action.payload);
    },
    setPages: (state, action) => {
      state.pages = action.payload;
    },
    resetPages: (state) => {
      state.pages = [];
    },
    setUrl: (state, action) => {
      state.url = action.payload;
    },
  },
})

export const { addPage, resetPages, setUrl, setPages } = totalPagesSlice.actions

export default totalPagesSlice.reducer