import "./Body.css";
import { useState } from "react";
import {
  Box,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
// import CircularProgress from '@mui/joy/CircularProgress';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, toggleLoading } from "../app/reducers/loadingSlice";
import { setUrl, addPage, setPages } from "../app/reducers/totalPagesSlice";
import { Crawler } from "../helper/Crawler";

function Form() {
  const [url, setURL] = useState("");
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.loading);

  const searchPages = async () => {
    dispatch(toggleLoading());
    dispatch(setUrl(url));
    try {
      const crawler = new Crawler(url, (_url) => dispatch(addPage(_url)));
      await crawler.fetch();
      console.log(crawler.visitedLinks);
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
      className="row align-items-center justify-content-center"
    >
      <TextField
      className="col-12 col-sm-7"
        label="List item"
        variant="outlined"
        value={url}
        size="small"
        color="primary"
        style={{ fontWeight: "bold" }}
        onChange={(ev) => setURL(ev.target.value)}
      />
      <Button
      className="col-12 col-sm-4"
        variant="contained"
        color="primary"
        size="medium"
        disableElevation
        style={{ fontWeight: "bold" }}
        onClick={searchPages}
      >
        Search
      </Button>
    </Box>
  );
}

export default Form;
