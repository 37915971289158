import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline, Typography } from "@mui/material";
import Body from "./components/Body";
import { StyledEngineProvider } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: { default: "#04220d", paper: "#122627" },
    primary: {
      main: "#016b6b",
      contrastText: "#fff",
    },
    secondary: {
      main: "#add270",
      contrastText: "#fff",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App">
        <header className="App-header">
          <Typography variant="h4" color="text.secondary" gutterBottom>
            Total Pages
          </Typography>
        </header>
        <Body></Body>
      </div>
    </ThemeProvider>
  );
}

export default App;
